import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["menu"]
  static values = { open: Boolean }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  close(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    this.openValue ? enter(this.menuTarget) : leave(this.menuTarget)
  }
}
