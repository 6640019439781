import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="header"
export default class extends Controller {

  openMobileSidebar() {
    this.mobileSidebarController.reveal()

    if (this.mobileSidebarController) {
      this.mobileSidebarController.reveal()
    }
  }

  get mobileSidebarController(){
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'mobile-sidebar';
    });
  }
}
