import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["alert"]

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  close(event){
    this.alertTarget.classList.add(this.toggleClass)
  }
}
