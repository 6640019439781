import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

// Connects to data-controller="mobile-sidebar"
export default class extends Controller {
  static targets = ['container', 'overlay', 'menu', 'button']

  reveal() {
    this.containerTarget.classList.add('z-50')
    this.containerTarget.classList.remove('hidden')
    enter(this.menuTarget)
    enter(this.overlayTarget)
    enter(this.buttonTarget)
  }

  close() {
    leave(this.buttonTarget)
    leave(this.overlayTarget)
    leave(this.menuTarget)
    setTimeout(() => this.containerTarget.classList.add('hidden'), 1000);
  }
}
