import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify";

// Connects to data-controller="tag"
export default class extends Controller {
  connect() {
    console.log('I am connected')
    this.tags();
  }

  tags() {
    var that = this;
    const url = '/languages.json';
    const titleInput = document.querySelector('input[id=achievement_tags]');
    const tag = new Tagify(titleInput, {
      whitelist: [],
      maxTags: 5,
      dropdown: {
        maxItems: 20, // <- mixumum allowed rendered suggestions
        classname: 'tags-look', // <- custom classname for this dropdown, so it could be targeted
        enabled: 0, // <- show suggestions on focus
        closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
      },
    });

    tag.on('focus', function (e) {
      console.log('I am connected')
      //that.loadWhiteList(e, tag, url);
    });
  }
}
